@import "../../../Styles/variables";

.crossword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.crossword_hintbtns {
  height: 6vh;
  width: 95vw;
  max-width: 200rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.crossword_container {
  height: 90vh;
  width: 95vw;

  max-height: 100rem;
  max-width: 200rem;

  border: 0.5rem solid $color_base_orange;

  padding: 1rem;

  background-color: $color_base_brown;

  display: flex;
}

.crossword_left {
  position: relative;

  flex: 0.3;

  background-image: url("https://storage.googleapis.com/stq_assets/images/stage_two/Left%20Panel.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.crossword_right {
  position: relative;

  flex: 0.7;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("https://storage.googleapis.com/stq_assets/images/stage_two/Right%20Panel%20(Compressed).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .crossword_grid_container {
    width: 80%;

    display: grid;
    grid-template-columns: repeat(22, 1fr);
    grid-template-rows: repeat(20, 3.5rem);

    border-collapse: collapse;

    .input_container {
      border: 1px solid #e6c99e;

      background-color: white;

      position: relative;

      height: 100%;
      width: 100%;

      span {
        position: absolute;
        top: -5%;
        left: 2%;
        font-size: 1.5rem;
        color: red;
      }

      input {
        border: none;

        height: 100%;
        width: 100%;

        font-size: 2.1rem;
        text-align: center;

        caret-color: transparent;

        outline: none;

        background-color: transparent;

        cursor: pointer;

        &:focus {
          background-color: #f9ef99;
        }
      }
    }
  }
}

.instructions_crossword {
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);

  z-index: 5;
}
