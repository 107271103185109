@import "../../Styles//variables";

.takeControlInfoAndBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 83%;
}

.takeControlInfo {
  height: 100%;

  color: $color_base_yellow_orange;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  font-size: 2rem;
  font-weight: 600;

  .takeControlInfo_label {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .takeControlInfo_value {
    color: #ffbb00;
    font-size: 3rem;
    font-weight: 600;
  }

  .takeControlInfo_value_dark {
    color: #b8251f;
    font-size: 3rem;
    font-weight: 600;
  }
}

.takeControlInfoDark {
  color: $color_base_brown;
}

.takeControlBtnDisbaled {
  pointer-events: none;
  opacity: 0.5;
}
