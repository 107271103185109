@import "../../../Styles/variables";

.chess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.chess_hintbtns {
  height: 6vh;
  width: 95vw;
  max-width: 200rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.chess_container {
  height: 90vh;
  width: 95vw;

  max-height: 100rem;
  max-width: 200rem;

  border: 0.5rem solid $color_base_orange;

  padding: 1rem;

  background-color: $color_base_brown;

  display: flex;
}

.chess_left {
  position: relative;

  flex: 0.3;

  background-image: url("https://storage.googleapis.com/stq_assets/images/stage_two/Left%20Panel.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.chess_right {
  position: relative;

  flex: 0.7;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("https://storage.googleapis.com/stq_assets/images/stage_two/Right%20Panel%20(Compressed).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .chess_white_pieces {
    margin-right: 5rem;
  }

  .chess_black_pieces {
    margin-left: 5rem;
  }

  .chess_grid_container {
    width: calc(30vh + 20vw);
    height: calc(30vh + 20vw);

    max-height: 75rem;
    max-width: 75rem;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);

    .chess_grid_cell {
      border: none;

      height: 100%;
      width: 100%;

      position: relative;

      .chess_cellNum {
        position: absolute;
        top: -3%;
        left: 5%;
        font-size: 2.5rem;
        font-weight: bold;
      }

      .chess_cellId {
        position: absolute;
        bottom: -3%;
        right: 5%;
        font-size: 2.5rem;
        font-weight: bold;
      }
    }

    .chess_grid_cell_piece_wrong {
      box-shadow: inset 0px 0px 15px 3px rgba(255, 0, 0, 0.75);
    }

    .chess_grid_cell_piece_fixed {
      box-shadow: inset 0px 0px 15px 3px rgba(51, 51, 51, 0.75);
      opacity: 0.5;
    }
  }

  .chess_solved_popup {
    position: absolute;

    width: calc(30vh + 20vw);
    height: calc(30vh + 20vw);

    max-height: 75rem;
    max-width: 75rem;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -70%);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 7;

    h1 {
      color: white;
      font-size: 7rem;
      font-weight: bold;
      text-align: center;
      width: 98%;

      text-shadow:
        2px 0 $color_base_brown,
        -2px 0 $color_base_brown,
        0 2px $color_base_brown,
        0 -2px $color_base_brown,
        1px 1px $color_base_brown,
        -1px -1px $color_base_brown,
        1px -1px $color_base_brown,
        -1px 1px $color_base_brown;
    }
  }

  .chess_solved_popup_animation {
    animation: animate_chess_solved_popup 3s linear;
  }

  @keyframes animate_chess_solved_popup {
    0% {
      transform: translate(-50%, -80%);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, -75%);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -70%);
      opacity: 1;
    }
  }
}

.instructions_chess {
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);

  z-index: 5;
}
