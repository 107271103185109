@import "../../Styles/variables";

.MazePuzzle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;

  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.sol {
  div {
    background: peachpuff;
    height: calc(0.25vh + 0.25vw);
    width: calc(0.25vh + 0.25vw);
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }
}

.currentPosition {
  div {
    background-image: url("https://storage.googleapis.com/stq_assets/images/stage_four/pos_maze.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }
}

.destination {
  div {
    background: SpringGreen;
    height: calc(0.75vh + 0.75vw);
    width: calc(0.75vh + 0.75vw);
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }
}

.visited {
  background: rgb(255, 183, 0);
  height: calc(0.2vh + 0.2vw);
  width: calc(0.2vh + 0.2vw);
  margin: 0 auto;

  border-radius: 10rem;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;

  div {
    margin-right: 0;
  }
}

.maze_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;

  padding: 1rem;

  .maze_solve_reward {
    position: absolute;
    bottom: calc(0.5vh + 0.5vw);
    right: calc(-1.5vh - 1.5vw);

    img {
      height: 12rem;
    }
  }

  .maze_start {
    position: absolute;
    top: calc(0.5vh + 0.5vw);
    left: calc(-1.5vh - 1.5vw);

    img {
      height: 4rem;
    }
  }
}

.maze_container_one {
  // border: 0.5rem solid  rgba(129, 117, 86, 1);
  background: rgba(97, 58, 52, 1);
}

.maze_container_two {
  // border: 0.5rem solid  rgba(149, 125, 0, 1);
  background: rgba(44, 38, 36, 1);
}

.maze_container_three {
  // border: 0.5rem solid rgba(161, 161, 157, 1);
  background: rgba(46, 46, 46, 1);
}

.maze {
  position: fixed;
  border-collapse: collapse;
  z-index: 2;
}

.maze_one {
  tbody {
    tr {
      .topWall {
        border-top: 0.5rem solid rgba(129, 117, 86, 1);
      }

      .rightWall {
        border-right: 0.5rem solid rgba(129, 117, 86, 1);
      }

      .bottomWall {
        border-bottom: 0.5rem solid rgba(129, 117, 86, 1);
      }

      .leftWall {
        border-left: 0.5rem solid rgba(129, 117, 86, 1);
      }
    }
  }
  z-index: 2;
}

.maze_two {
  tbody {
    tr {
      .topWall {
        border-top: 0.5rem solid rgba(149, 125, 0, 1);
      }

      .rightWall {
        border-right: 0.5rem solid rgba(149, 125, 0, 1);
      }

      .bottomWall {
        border-bottom: 0.5rem solid rgba(149, 125, 0, 1);
      }

      .leftWall {
        border-left: 0.5rem solid rgba(149, 125, 0, 1);
      }
    }
  }
  z-index: 2;
}

.maze_three {
  tbody {
    tr {
      .topWall {
        border-top: 0.5rem solid rgba(161, 161, 157, 1);
      }

      .rightWall {
        border-right: 0.5rem solid rgba(161, 161, 157, 1);
      }

      .bottomWall {
        border-bottom: 0.5rem solid rgba(161, 161, 157, 1);
      }

      .leftWall {
        border-left: 0.5rem solid rgba(161, 161, 157, 1);
      }
    }
  }
  z-index: 2;
}

// for adding shadow to the maze
.maze_shadow {
  position: absolute;
  transform: translateX(0.5rem) translateY(0.5rem);

  border-collapse: collapse;
  tbody {
    tr {
      .topWall {
        border-top: 0.5rem solid rgba(0, 0, 0, 0.177);
      }

      .rightWall {
        border-right: 0.5rem solid rgba(0, 0, 0, 0.177);
      }

      .bottomWall {
        border-bottom: 0.5rem solid rgba(0, 0, 0, 0.177);
      }

      .leftWall {
        border-left: 0.5rem solid rgba(0, 0, 0, 0.177);
      }
    }
  }
}

.maze_complete {
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.8);

  z-index: 5;

  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .solved_text {
    height: 70vh;
    margin-bottom: 15vh;
  }

  .solved_img {
    position: fixed;
    bottom: 20vh;
    height: 25vh;
  }
}
