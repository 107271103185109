@import "../../../Styles/variables";

.endScreen {
  height: 100vh;
  width: 100vw;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: $color_base_yellow_orange;
    font-size: 5rem;
    font-weight: 700;
  }
}
