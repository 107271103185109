@import "./../../Styles/_variables.scss";

.combinationLockInput {
  input {
    width: 8rem;
    height: 10rem;

    font-size: 5rem;
    font-weight: 500;
    color: $color_base_yellow_orange;

    background-color: $color_brown_transparent;

    text-align: center;

    margin: 0 1rem;

    border: 0.2rem solid $color_base_orange;
    border-radius: 0.5rem;

    &:focus {
      outline: none;
    }
  }

  .combinationLockInput_upwardTringle {
    margin-bottom: 1rem;

    &:active {
      transform: scale(0.9);
    }
  }

  .combinationLockInput_downwardTringle {
    margin-top: 1rem;
    transform: rotate(180deg);

    &:active {
      transform: scale(0.9) rotate(180deg);
    }
  }

  .combinationLockInput_tringle {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 1rem;
      width: 1rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
