@import "../../Styles/variables";

.color_control {
  flex: 0.25;
  height: 100%;

  background-color: rgb(0, 0, 0);
  box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;

  border-left: 0.5rem solid $color_base_orange;
  background-image: url("https://storage.googleapis.com/stq_assets/images/common/common_bg%20(Compressed).png");
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 1rem;
}

.color_control_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 100%;
}

.reference_image {
  cursor: pointer;

  border: 0.5rem solid $color_base_orange;
  box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;

  background-color: #eddaa0;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  margin: 0.5rem 0rem;

  height: 60%;
  img {
    height: 100%;
  }
}

.color_selector {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  border: 0.3rem solid $color_base_orange;
  box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;
  background-color: #eddaa0;

  .color_picker_and_earser {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .erase_color {
      border: none;
      outline: none;
      border: 0.3rem solid $color_base_orange;
      width: 8rem;
      height: 4.5rem;
      margin: 0 0.5rem;
      padding: 0;
      outline: none;
      vertical-align: baseline;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color_base_brown;

      img {
        height: 3rem;
      }
    }

    .save_color {
      width: 12rem;
      min-width: unset;
      height: 4.5rem;
      font-size: 2rem;
      padding: 1rem;
    }
  }

  .color_control_undo_redo {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .undo,
    .redo {
      width: 6rem;
      height: 5rem;
      cursor: pointer;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      img {
        width: 3.5rem;

        transform: rotate(165deg);

        transition: trasform 0.2s ease;

        &:hover {
          transform: scale(1.05) rotate(165deg);
        }

        &:active {
          transform: scale(0.9) rotate(165deg);
        }
      }

      span {
        font-size: 1.3rem;
        color: $color_base_brown;
        font-weight: bold;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.color_cell {
  width: 12%;
  height: 3rem;
  border: 0.3rem solid $color_base_orange;
  border-radius: 0.2rem;
  cursor: pointer;

  transition: trasform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.base_color_container,
.saved_color_container {
  border: 0.3rem solid $color_base_orange;
  border-radius: 0.2rem;
  box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;
  background-color: #eddaa0;
  width: 100%;
  padding: 0.5rem 1rem;

  h3 {
    font-size: 1.6rem;
    color: $color_base_brown;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .colors_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .erase_color {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 12%;
    height: 3rem;
    border: 0.3rem solid $color_base_orange;
    box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;
    border-radius: 0.2rem;
    cursor: pointer;

    transition: trasform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }

    img {
      height: 1.5rem;
    }

    &:hover {
      transform: scale(1.01);
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

.color_control_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
