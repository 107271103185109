@import "../../Styles/variables";

.kingImagePopup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  z-index: 4;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  background-size: 100% 100%;

  .kingImagePopupContainer {
    padding: 2rem;

    border: 0.5rem solid $color_base_orange;
    box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;

    background-color: #eddaa0;

    display: flex;
    justify-content: center;
    align-items: center;

    .kingImagePopupContainerImageContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 90vh;
      }
    }
  }
}
