.video_screen {
  height: 100vh;
  width: 100vw;

  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;

  video {
    height: 100vh;
    width: 100vw;
    object-fit: fill;
  }
}

.videoScreenButton {
  img {
    height: 10rem;
    width: 10rem;

    cursor: pointer;

    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }
}
