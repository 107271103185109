.volSlider {
  margin: 2rem;
  box-shadow: none;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 3rem;
    width: 3rem;

    margin-right: 2rem;
  }
}

.MuiSlider-thumb {
  box-shadow: none !important;
  height: 3rem !important;
  width: 3rem !important;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/icons/volume_on.png") !important;
  background-size: cover !important;
}

.css-14gyywz-MuiSlider-valueLabel {
  background-color: #f6e5b4 !important;
  font-size: 2rem !important;
  color: #5d5536 !important;
  font-weight: 800 !important;
}
