@import "../../styles/variables.scss";

.hintboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  padding-top: 5rem;

  .hintbaord_active_panel {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-left: 22%;

    .hintboard_active_panel_text {
      color: $color_base_brown;
      font-size: 2.5rem;
      margin-bottom: 0;
    }
  }

  .hints_container {
    width: 85%;
    height: 60rem;

    box-shadow: 0.3rem 0.3rem 0.5rem #00000054;

    @media screen and (max-height: 1050px) {
      height: 50rem;
    }

    padding: 2.5rem 0;

    background-image: url("https://storage.googleapis.com/stq_assets/images/crossword/hintboard_bg%20(Compressed)png.png");
    background-size: 100% 100%;

    border: 0.5rem solid $color_base_orange;
    border-radius: 0.5rem;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .hint {
      width: 95%;

      display: flex;
      margin-bottom: 0.5rem;
      padding: 0.5rem 1rem;

      border-radius: 0.5rem;

      color: $color_base_brown;

      .hint_index {
        font-size: 2rem;
        font-weight: 600;
      }

      .hint_index_gap_small {
        width: 15%;
      }

      .hint_index_gap_large {
        width: 30%;
      }

      .hint_text {
        width: 100%;
        font-size: 2rem;
        font-weight: 600;
      }

      cursor: pointer;

      &:hover {
        background-color: #0000000e;
      }
    }

    .hint_state_STRIKED {
      text-decoration: line-through;
    }

    .hint_state_HIGHLIGHTED {
      background-color: rgba(217, 126, 4, 0.4) !important;
    }

    .hint_State_DISBALED {
      pointer-events: none;
    }

    .hint_State_LOCKED {
      pointer-events: none;
      span {
        color: gray;
      }
    }
  }

  .hints_container::-webkit-scrollbar {
    width: 0.8rem;
    padding: 1rem;
  }

  .hints_container::-webkit-scrollbar-track {
    background-color: #e4e4e400;
    border-radius: 10rem;
  }

  .hints_container::-webkit-scrollbar-thumb {
    background-color: #885309;
    border-radius: 10rem;
  }

  .hintboard_btn_container {
    width: 100%;

    margin-top: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;

    .hintboard_btn {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0.5rem;

      border-radius: 0.5rem;

      cursor: pointer;
    }
  }
}
