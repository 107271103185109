.draggable_items {
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  cursor: grab;
  position: absolute;
  z-index: 1;
  height: 50px;

  img {
    height: 35px;
    width: 35px;

    &:hover {
      cursor: pointer;
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

.draggable_item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.peieceNotSynced {
  opacity: 0.5;
  transition: all 0.5s;
}

.animateDraggables {
  transition: all 0.5s;
}
