@import "../../../Styles/variables";

.karaokeScreen {
  height: 100vh;
  width: 100vw;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .karaokeScreen_logo {
    height: 10rem;
  }

  .karaoke__video {
    position: relative;

    height: 80vh;
    width: 80vw;

    .karaokeScreenPlay_btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      height: 8rem;
      width: 8rem;

      z-index: 3;

      transition: transform 0.2s ease-in-out;

      &:active {
        transform: scale(0.95) translate(-50%, -50%);
      }
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  .karaoke_btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
