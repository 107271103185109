@import "../../../Styles/variables.scss";

.stageOne {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.stageOne_btns {
  height: 8rem;

  position: absolute;
  top: 50%;
  left: 50%;

  z-index: 6;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .left_btns {
    flex: 0.4;
  }

  .right_btns {
    flex: 0.6;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.music_controls {
  position: absolute;
  top: -20%;
  left: 5%;

  z-index: 1;

  img {
    height: 40px;
    width: 40px;

    &:hover {
      cursor: pointer;
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

.music_board {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 750px;
  width: 1300px;

  @media screen and (max-height: 850px) {
    height: 700px;
  }

  padding: 0 50px;

  background-image: url("https://storage.googleapis.com/stq_assets/images/stage_one/music_board_bg%20(Compressed).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .instruments_container {
    position: relative;

    width: 200px;

    border: 5px solid $color_base_orange;
    border-right: none;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .instrument {
      height: 100px;
      width: 200px;

      background-image: url("https://storage.googleapis.com/stq_assets/images/stage_one/music_board_ins_bg.png");

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: relative;

      .instrument_image {
        img {
          height: 70px;
        }
      }

      .instrument_name_and_silent_btn {
        span {
          font-size: 20px;
          font-weight: bold;
          color: $color_base_red;
        }

        .instrument_silent_btn {
          position: absolute;
          bottom: 5%;
          right: 10%;

          img {
            height: 30px;
            width: 30px;
          }

          &:hover {
            cursor: pointer;
          }

          &:active {
            transform: scale(0.9);
          }
        }
      }
    }

    .instrument_silent {
      opacity: 0.4;
    }

    // add border-bottom to all except the last one
    .instrument:not(:last-child) {
      border-bottom: 5px solid $color_base_orange;
    }

    .instrument {
      border-left: 2px solid $color_base_orange;
    }
  }

  .target_container {
    position: relative;

    border: 0.5rem solid $color_base_orange;

    background-image: url("https://storage.googleapis.com/stq_assets/images/stage_one/music_board_ins_bg.png");
    background-size: 100% 100%;

    .target_one,
    .target_two,
    .target_three {
      height: 100px;
    }

    .target_one,
    .target_two {
      border-bottom: 5px solid $color_base_orange;
    }

    .target_silent {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.animationContainer {
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 7;

  display: flex;
  justify-content: center;
  align-items: center;
}

.instructions_stageone {
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);

  z-index: 7;
}
