@import "../../Styles/variables";

.instructionPop {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  z-index: 3;

  padding: 6rem 8rem;

  background-image: url("https://storage.googleapis.com/stq_assets/images/instruction/Background.png");
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instructionPop_small {
  width: calc(22vh + 22vw);

  // one more height on smaller screens
  @media (max-height: 876px) {
    width: calc(23vh + 23vw);
  }

  // give more height on smaller screens
  @media (max-height: 768px) {
    width: calc(24vh + 24vw);
  }

  // one more height on smaller screens
  @media (max-height: 576px) {
    width: calc(25vh + 25vw);
  }
}

.instructionPop_wide {
  width: calc(25vh + 25vw);

  // one more height on smaller screens
  @media (max-height: 876px) {
    width: calc(28vh + 28vw);
  }

  // give more height on smaller screens
  @media (max-height: 768px) {
    width: calc(30vh + 30vw);
  }

  // one more height on smaller screens
  @media (max-height: 576px) {
    width: calc(32vh + 32vw);
  }
}

.instructionPop_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .instructionPop_content_img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    img {
      width: 100%;
    }
  }

  .instructionPop_content_title {
    height: calc(1vh + 1vw);
    font-size: 3rem;
    font-weight: 700;
    color: $color_black;
    margin-top: 0;
  }

  .instructionPop_content_description {
    height: calc(3vh + 3vw);
    width: 100%;
    margin-top: 3rem;

    // one more height on smaller screens
    @media (max-height: 876px) {
      height: calc(4vh + 4vw);
    }

    // give more height on smaller screens
    @media (max-height: 768px) {
      height: calc(5vh + 5vw);
    }

    // one more height on smaller screens
    @media (max-height: 576px) {
      height: calc(6vh + 6vw);
    }

    font-size: 2rem;
    font-weight: 500;
    color: $color_black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.instructionPop_next {
  display: flex;
  justify-content: center;
  align-items: center;

  .instructionPop_next_button {
    margin-bottom: 0;
  }
}

.instructionPop_progress {
  margin: 3rem 0;
  height: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .instructionPop_progress_bar {
    height: 1rem;
    border-radius: 10px;
    margin: 0 0.5rem;
    width: 100%;
    background-color: $color_base_brown;

    .instructionPop_progress_bar_fill {
      height: 100%;
      border-radius: 10px;
      background-color: $color_base_orange;
    }
  }
}

.instructionScreen_skip_button {
  position: fixed;
  top: 2rem;
  right: 2rem;
  padding: 0 3rem;

  z-index: 2;

  img {
    height: 3rem;
    margin-left: 1rem;
  }
}
