@import "./Styles/variables";

* {
  box-sizing: border-box;

  // default font
  font-family: "Crimson Text", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // disable ghosting on touch devices and disable text selection and dragging
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -webkit-user-drag: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

html {
  font-size: 10px;

  @media screen and (max-height: 990px) {
    font-size: 9px;
  }

  @media screen and (max-height: 850px) {
    font-size: 8px;
  }

  @media screen and (max-height: 750px) {
    font-size: 7px;
  }
}

body {
  margin: 0;

  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

// disable text selection
::selection {
  background: transparent;
}
::-moz-selection {
  background: transparent;
}

// global styles

.common_btn {
  font-size: 2.5rem;
  margin: 1rem;
  height: 5rem;
  min-width: 18rem;
  padding: 0.5rem 1rem;

  color: $color_base_yellow_orange;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  bottom: none;
  outline: none;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/Button.png");
  background-size: 100% 100%;

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }

  &:active {
    transform: scale(0.95);
  }
}

.common_btn_secondary {
  font-size: 2.5rem;
  margin: 1rem;
  height: 5rem;
  min-width: 18rem;
  padding: 0.5rem 1rem;

  color: $color_base_yellow_orange;

  background-color: rgba(84, 56, 55, 1);

  display: flex;
  justify-content: center;
  align-items: center;

  bottom: none;
  outline: none;
  border: 0.3rem solid $color_base_orange;
  box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }
}
