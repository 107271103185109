@import "../../Styles/variables";

.draw_area {
  flex: 0.75;

  width: 95%;
  height: 95%;

  display: flex;
  justify-content: center;
  align-items: center;

  .draw_grid {
    display: grid;
    width: 95%;
    height: 95%;

    .draw_cell {
      border: 0.1rem solid rgba(0, 0, 0, 0.092);
    }

    .draw_cell_active {
      background-color: #000;
    }
  }
}
