@import "../../../Styles/variables";

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100vh;
  width: 100vw;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .loader_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    z-index: 5;
  }

  .loader_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    z-index: 6;

    h1 {
      color: $color_base_yellow_orange;
      font-weight: 100;
    }
  }
}

.progress {
  width: 100%;
  height: 3rem !important;
  border-radius: 3rem !important;
  margin: 3rem;
}
