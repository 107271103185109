.combinationLockSolvedPopup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 45rem;
  width: 60rem;

  padding: 8rem 8rem 6rem 8rem;

  background-image: url("https://storage.googleapis.com/stq_assets/images/combination_lock/Background%20(Compressed).png");
  background-size: 100% 100%;

  h3 {
    font-size: 2.5rem;
    width: 80%;
    text-align: center;
    margin-bottom: 0px;
    font-weight: 600;
  }

  img {
    height: 14rem;
    transform: rotate(60deg);
  }

  .combinationLockSolvedPopup_closeBtn {
    margin: 0;
  }
}
