@import "../../Styles/variables";

.popup_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
}

.popup_content {
  background-image: url("https://storage.googleapis.com/stq_assets/images/common/bg_two.png");
  background-size: 100% 100%;

  min-height: 30rem;
  width: 60rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  text-align: center;

  padding: 6rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .popup_message {
    font-weight: 800;
    font-size: 3em;
    color: $color_base_brown;
    margin-bottom: 2rem;
  }

  .popup_sub_message {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .popup_img {
    height: 10rem;
    width: 15rem;
    margin: 0 auto;
    margin-bottom: 3rem;
  }

  .popup_btns {
    display: flex;
    justify-content: space-between;
    padding: 0 4%;

    div {
      margin-bottom: 0;
    }
  }
}
