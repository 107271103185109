@import "../../../Styles/variables";

.login_page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;

  .game_logo {
    margin: 5rem;
    img {
      height: 10rem;
    }
  }
}

.login_form {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/bg_one%20(Compressed).png");
  background-size: 100% 100%;

  padding: 5rem 0;

  height: 55rem;
  width: 60rem;

  h1 {
    font-size: 3rem;
    font-weight: 800;
    color: $color_base_brown;
    margin-bottom: 2rem;
  }

  .login_form_input {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 60%;

    label {
      font-weight: bold;
      color: $color_base_brown;

      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    input {
      width: 100%;

      font-size: 2rem;
      padding: 0.8rem 1rem;

      color: $color_base_yellow_orange;

      outline: none;

      border: 0.3rem solid $color_base_orange;
      border-radius: 0.4rem;

      background-color: $color_base_light_brown;

      &:focus {
        opacity: 0.95;
      }
    }
  }

  .login_form_checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 3rem;
    width: 60%;

    margin-top: 1rem;

    label {
      height: 100%;
      font-size: 2rem;
      font-weight: bold;

      color: $color_base_brown;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 2rem;
    }

    input {
      accent-color: $color_base_brown;

      height: 3rem;
      width: 3rem;

      background-color: $color_base_light_brown;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        opacity: 0.95;
      }

      &:checked {
        background-color: $color_base_orange;
      }
    }
  }

  .login_form_checkbox input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */

    height: 3rem;
    width: 3rem;
    border-radius: 0.15em;
    border: 0.2em solid $color_base_orange;
    outline: none;
    cursor: pointer;
  }

  input.is_host_checked {
    background-color: $color_base_orange;
    position: relative;
  }
  input.is_host_checked::before {
    content: "\2713";
    font-size: 2em;
    font-weight: bold;
    color: $color_base_brown;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(45%, -50%);
  }
}
