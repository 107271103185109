@import "../../../Styles/variables";

.waiting_screen {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
}

.waitin_screen_board {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/bg_one%20(Compressed).png");
  background-size: 100% 100%;

  padding: 7rem 0;

  height: 70rem;
  width: 70rem;

  h1 {
    font-size: 3rem;
    font-weight: 800;
    color: $color_base_brown;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    color: $color_base_brown;
    margin-bottom: 2rem;
  }

  .player_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 90%;

    margin-top: 1rem;

    height: 40rem;

    overflow-y: scroll;

    list-style: none;

    li {
      font-size: 2rem;
      color: $color_base_brown;
      margin-bottom: 2rem;

      height: 3rem;

      text-align: center;

      width: 80%;

      border-bottom: 0.1rem solid rgba(43, 9, 8, 0.2);

      padding-bottom: 0.5rem;
    }
  }

  .player_list::-webkit-scrollbar {
    width: 0.8rem;
    padding: 1rem;
  }

  .player_list::-webkit-scrollbar-track {
    background-color: #e4e4e400;
    border-radius: 10rem;
  }

  .player_list::-webkit-scrollbar-thumb {
    background-color: #885309;
    border-radius: 10rem;
  }
}
