.chess_piece {
  width: calc(2vh + 2vw);
  height: calc(2vh + 2vw);

  max-height: 7rem;
  max-width: 7rem;
}

.chess_piece_transition {
  transition: all 1s ease;
}

.animate_chess_piece {
  transition: all 3s;
  pointer-events: none;

  animation: animate_chess_piece_opacity 3s linear;
}

@keyframes animate_chess_piece_opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.chess_piece {
  .draggable_item {
    img {
      height: 7rem;
      width: 7rem;
      cursor: grab;
    }
  }
}
