@import "../../Styles/variables";

.timeline {
  height: 50px;

  position: absolute;

  top: -20%;

  .timeline_cursor {
    position: absolute;
    width: 2px;
    background-color: #000000;

    height: 360px;

    z-index: 6;

    .timeline_cursor_handle {
      cursor: grab;

      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;

      border-top: 10px solid black;
    }
  }

  // .timeline_cursor_animate {
  //   transition: all 1s linear;
  // }

  .timeline_lines {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    height: 50px;

    .timeline_line {
      width: 1px;
      background-color: $color_base_yellow_orange;

      span {
        pointer-events: none;

        font-size: 15px;
        font-weight: bold;
        color: $color_base_orange;

        position: absolute;
        top: -2%;
        transform: translateX(-50%);
      }
    }

    .timeline_line_big {
      height: 30px;
    }

    .timeline_line_small {
      height: 10px;
    }
  }
}
