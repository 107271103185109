@import "../../Styles/variables";

.custom_color_picker_container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.select_color_box {
  border: none;
  outline: none;
  border: 0.3rem solid $color_base_orange;
  width: 8rem;
  height: 4.5rem;
  margin: 0 0.5rem;
  padding: 0;
  outline: none;
}

.myPicker {
  padding: 1rem;
  background: $color_base_yellow_orange;
  backdrop-filter: blur(0.5rem);
  border-radius: 0.2rem;
  border: 0.3rem solid $color_base_orange;
  box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(74, 24, 13, 0.65) inset;
}

.close_picker_button {
  position: absolute;
  top: -8%;
  right: -8%;
  z-index: 2;
  background: white;
  border-radius: 50%;

  height: 4rem;
  width: 4rem;

  border: 0.3rem solid $color_base_orange;
  box-shadow: 0.15rem 0.2rem 0.1rem 0rem rgba(48, 8, 0, 0.65) inset;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $color_base_brown;

  text-align: center;

  font-size: 2.5rem;
  font-weight: bold;

  padding-top: 0.3rem;

  cursor: pointer;
  background: $color_base_yellow_orange;

  img {
    height: 80%;
    width: 80%;
  }

  &:active {
    transform: scale(0.95);
  }
}
