@import "../../../Styles/variables";

.draw_the_king {
  height: 100vh;
  width: 100vw;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
}

.draw_components_container {
  height: 95%;
  width: 95%;

  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(255, 255, 255);
  box-shadow: 1.5px 2px 1px 0px rgba(48, 8, 0, 0.65) inset;

  border: 5px solid $color_base_orange;
  background-image: url("https://storage.googleapis.com/stq_assets/images/common/common_bg%20(Compressed).png");
}

.mission_end_popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;

  .mission_end_popup_img {
    width: 80%;

    img {
      width: 100%;
    }
  }
}
