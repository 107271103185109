.combinationLock_container {
  position: fixed;

  height: 100vh;
  width: 100vw;

  top: 0;
  left: 0;

  z-index: 5;

  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.combinationLock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 50rem;
  width: 60rem;

  background-image: url("https://storage.googleapis.com/stq_assets/images/combination_lock/Background%20(Compressed).png");
  background-size: 100% 100%;

  padding: 4rem;
}

.combinationLock_inputs {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 2rem;

  input {
    width: 4rem;
    height: 6rem;

    font-size: 2rem;

    text-align: center;

    margin: 0 1rem;

    border: 0.1rem solid black;
    border-radius: 0.5rem;

    &:focus {
      outline: none;
    }
  }
}

.combinationLockBtns {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 2rem;

  button {
    width: 10rem;
    height: 4rem;

    font-size: 1.5rem;

    margin: 0 1rem;

    border: 0.1rem solid black;
    border-radius: 0.5rem;

    &:focus {
      outline: none;
    }
  }
}
