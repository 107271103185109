@import "../../../Styles/variables";

.mazePuzzle {
  height: 100vh;
  width: 100vw;

  background-image: url("https://storage.googleapis.com/stq_assets/images/common/base_image.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .mazePuzzle_overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
  }

  h1 {
    position: absolute;
    top: 10rem;
    color: $color_base_yellow_orange;
    font-size: 3.5rem;
    font-weight: 700;
  }

  .end_maze_btn {
    position: absolute;
    bottom: 5rem;
  }
}

.mazePuzzle_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 90%;
  max-width: 160rem;

  display: flex;
  justify-content: space-around;
  align-items: center;

  .maze_portal {
    background-image: url("https://storage.googleapis.com/stq_assets/images/stage_four/jewel_bg%20(Compressed).png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 60rem;
    width: 45rem;

    padding: 6rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h1 {
      position: static;
      color: $color_base_brown;
      font-size: 3rem;
      font-weight: 700;
      margin-top: 0;
    }

    img {
      height: 30rem;
    }

    h2 {
      color: red;
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    .common_btn {
      margin-bottom: 0;
    }
  }
}
